<template>
  <CRow>
    <CCol>
      <div v-if="serverErrors.length">
        <CAlert v-model="showDismissibleAlert" color="warning" closeButton>
          <ul>
            <li v-for="err in serverErrors" :key="err">{{ err }}</li>
          </ul>
        </CAlert>
      </div>
      <form v-on:submit.prevent="onSubmitted">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Name</label>
              <input
                type="text"
                class="form-control"
                v-model="role.name"
                placeholder="Enter Role Name"
                required
              />
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label>Display Name</label>
              <input
                type="text"
                class="form-control"
                v-model="role.display_name"
                placeholder="Enter Display Name"
                required
              />
            </div>
          </div>         
          <div class="form-group col-md-12 perm-wrapper" v-if="permissionGroups">
           <label><strong>Assign Permissions</strong></label>

            <div class="row perm-group-card" v-for="(permissions, index) in permissionGroups" :key="index">
              <div class="col-md-12">
                <h6><strong>Module: {{index}}</strong></h6>
              </div>
              <div
                class="checkbox col-md-3"
                v-for="permission in permissions"
                :key="permission.id"
              >
                <label :title="permission.display_name"
                  ><input
                    type="checkbox"
                    v-model="role.permissions"
                    :value="permission.id"
                  />
                  {{ permission.name }}</label
                >
              </div>
            </div>
          </div>
        </div>

        <button type="submit" class="btn btn-primary">Submit</button>
      </form>
    </CCol>   
  </CRow>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    roleId: Number,
  },
  data: () => {
    return {      
      serverErrors: [],
      showDismissibleAlert: false,
    };
  }, 
  methods: {
    onSubmitted() {
      this.$store
        .dispatch("Roles/updateRole", this.role)
        .then(() => {
           this.$toastr.s("Role Permissions successfully Updated.", "Permission Updated");
          setTimeout(() => {
            this.$emit("onEditedRole");
          }, 300);
        })
        .catch((err) => {
          this.showDismissibleAlert = true;
          this.serverErrors = [];
          if (err.response.data.message) {
            this.serverErrors.push(err.response.data.message);
          }

          let errors = err.response.data.errors;
          for (let field of Object.keys(errors)) {           
             this.$toastr.e( "error, " + errors[field][0] + ", Error!");
          }
        });
    },
    groupBy(xs, key) {
      return xs.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    }
  },
  computed: {
    ...mapGetters("Roles", ["role"]),
    ...mapGetters("Permission", ["permissions"]),
    permissionGroups() {
      return this.groupBy(this.permissions, 'group_name')
    }
  },
  mounted() {
    this.$store.dispatch("Roles/getRole", this.roleId);
    this.$store.dispatch("Permission/getPermissions");
  },
};
</script>
<style lang="scss" scoped>
.perm-group-card {
  background-color: #FAFAFA;
  padding: 5px 4px;
  margin: 8px 0px;
  border-radius: 6px;
}
.perm-wrapper {
  height: calc(100vh - 270px);
  overflow-y: scroll;
}
</style>