<script>
import SearchAndActions from "@/components/Table/SearchAndActions.vue";
import Header from "@/components/Table/Header.vue";
import axios from "axios";
import DataTable from "@/components/Table/DataTable.vue";
import ActionsButton from "@/components/Table/ActionsButton.vue";
import TablePagination from "@/components/Table/TablePagination.vue";
import EditRole from "@/components/roles/EditRole.vue";
import CreateRole from "@/components/roles/CreateRole.vue";

export default {
  name: "Roles",
  components: {CreateRole, EditRole, TablePagination, ActionsButton, DataTable, Header, SearchAndActions},
  data() {
    return {
      breadcrumbs: [{text: "Home", path: "/dashboard", isActive: false}, {
        text: "Roles",
        path: "/roles",
        isActive: true
      }],
      tableData: [],
      pagination: {
        current_page: 1,
        per_page: 10,
        total: 0,
        last_page: 0,
      },
      search: null,
      sort: '-created_at',
      date: null,
      createRoleModal: false,
      editRoleModal: false,
      roleId: "",
    }
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
  mounted() {
    let page = this.$route.query.page ? this.$route.query.page : 1;
    let per_page = this.$route.query.per_page ? this.$route.query.per_page : 10;
    let search = this.$route.query.search ? this.$route.query.search : null;
    let sort = this.$route.query.sort ? this.$route.query.sort : '-created_at';
    let date = this.$route.query.date ? this.$route.query.date : null;

    this.getListData(page, per_page, search, sort, date);
  },
  methods: {
    getListData(page = 1, per_page = 10, search = null, sort = '-created_at', date = null) {
      let url = '/admin/roles-list';
      let params = {
        page: page,
        per_page: per_page ? per_page : this.pagination.per_page,
        search: search ? search : this.search,
        sort: sort,
        date: date ? date : this.date,
      };

      axios.get(url, {params})
          .then(response => {
            this.tableData = response.data.data;
            this.pagination = response.data.meta;
            this.sort = sort;

            this.$router.replace({query: params});
          })
    },
    onPageChange(page) {
      this.getListData(page, this.pagination.per_page, this.search, this.sort, this.date);
    },
    onPageSizeChanged(pageSize) {
      this.getListData(1, pageSize, this.search, this.sort, this.date);
    },
    onSearch(search) {
      this.search = search ? search : null;
      this.getListData(1, this.pagination.per_page, this.search, this.sort, this.date);
    },
    sortBy(field) {
      let sort = this.sort === field ? '-' + field : field;
      this.getListData(1, this.pagination.per_page, this.search, sort, this.date);
    },
    onDate(date) {
      this.date = date ? date.join(',') : null;
      this.getListData(1, this.pagination.per_page, this.search, this.sort, this.date);
    },
    dismissCreateRoleModal() {
      this.createRoleModal = false;
    },
    dismissEditRoleModal() {
      this.editRoleModal = false;
    },
    editRole(roleId) {
      this.roleId = roleId;
      this.editRoleModal = true;
    },
  }
}
</script>

<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h6 class="text-uppercase font-weight-bold text-dark">Roles</h6>
      </div>

      <div class="col-md-12">
        <div class="card p-4">
          <SearchAndActions
              :show-date="false"
              :show-import="false"
              @search="onSearch"
              @date="onDate"
              action-url="/admin/roles-list"
              download-file-name="roles"
          >
            <el-button
                v-if="checkUsrPermissions(['Role_all', 'Role_create'])"
                type="primary"
                @click="createRoleModal = true"
                size="small"
                class="ml-2"
                icon="el-icon-plus"
            >
              Add Role
            </el-button>
          </SearchAndActions>

          <div class="row">
            <div class="col-md-12">
              <DataTable>
                <template #header>
                  <Header :sort="false" title="Sl"/>
                  <Header active="display_name" :ordering="sort" @sortBy="sortBy('display_name')" title="Display Name"/>
                  <Header active="name" :ordering="sort" @sortBy="sortBy('name')" title="Name (Unique)"/>
                  <Header
                      :sort="false"
                      title="Actions"
                      v-if="
                          checkUsrPermissions([
                            'Role_all',
                            'Role_edit'
                          ])
                        "
                  />
                </template>

                <tr
                    v-for="(data, index) in tableData"
                    :key="data.id"
                >
                  <th scope="row">
                    {{ pagination.from + index }}
                  </th>
                  <td>
                    {{ data.display_name }}
                  </td>
                  <td>
                    <span class="badge badge-primary role">
                      {{ data.name }}
                    </span>
                  </td>
                  <td
                      v-if="
                          checkUsrPermissions([
                            'Role_all',
                            'Role_edit'
                          ])
                        "
                  >
                    <ActionsButton>
                      <a
                          v-if="checkUsrPermissions(['Role_all', 'Role_edit'])"
                          href="#"
                          @click="editRole(data.id)"
                      >
                        Edit
                      </a>
                    </ActionsButton>
                  </td>
                </tr>
              </DataTable>

              <div class="mt-5" v-if="tableData.length">
                <TablePagination
                    :pagination="pagination"
                    @pagechanged="onPageChange"
                    @pagesizechanged="onPageSizeChanged"
                />
              </div>

              <div v-else>
                <p class="text-center font-weight-bold">
                  No data found
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <CModal
        v-if="createRoleModal"
        :closeOnBackdrop="false"
        size="xl"
        title="Add Role"
        color="primary"
        class="hide-footer custom-close-button"
        :show.sync="createRoleModal"
    >
      <CreateRole @onSavedRole="dismissCreateRoleModal"></CreateRole>
    </CModal>

    <CModal
        v-if="editRoleModal"
        :closeOnBackdrop="false"
        size="xl"
        title="Edit Role"
        color="primary"
        class="hide-footer custom-close-button"
        :show.sync="editRoleModal"
    >
      <EditRole
          :roleId="roleId"
          @onEditedRole="dismissEditRoleModal"
      ></EditRole>
    </CModal>
  </div>
</template>