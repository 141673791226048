var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card p-4"},[_c('SearchAndActions',{attrs:{"show-date":false,"show-import":false,"action-url":"/admin/roles-list","download-file-name":"roles"},on:{"search":_vm.onSearch,"date":_vm.onDate}},[(_vm.checkUsrPermissions(['Role_all', 'Role_create']))?_c('el-button',{staticClass:"ml-2",attrs:{"type":"primary","size":"small","icon":"el-icon-plus"},on:{"click":function($event){_vm.createRoleModal = true}}},[_vm._v(" Add Role ")]):_vm._e()],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('DataTable',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('Header',{attrs:{"sort":false,"title":"Sl"}}),_c('Header',{attrs:{"active":"display_name","ordering":_vm.sort,"title":"Display Name"},on:{"sortBy":function($event){return _vm.sortBy('display_name')}}}),_c('Header',{attrs:{"active":"name","ordering":_vm.sort,"title":"Name (Unique)"},on:{"sortBy":function($event){return _vm.sortBy('name')}}}),(
                        _vm.checkUsrPermissions([
                          'Role_all',
                          'Role_edit'
                        ])
                      )?_c('Header',{attrs:{"sort":false,"title":"Actions"}}):_vm._e()]},proxy:true}])},_vm._l((_vm.tableData),function(data,index){return _c('tr',{key:data.id},[_c('th',{attrs:{"scope":"row"}},[_vm._v(" "+_vm._s(_vm.pagination.from + index)+" ")]),_c('td',[_vm._v(" "+_vm._s(data.display_name)+" ")]),_c('td',[_c('span',{staticClass:"badge badge-primary role"},[_vm._v(" "+_vm._s(data.name)+" ")])]),(
                        _vm.checkUsrPermissions([
                          'Role_all',
                          'Role_edit'
                        ])
                      )?_c('td',[_c('ActionsButton',[(_vm.checkUsrPermissions(['Role_all', 'Role_edit']))?_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.editRole(data.id)}}},[_vm._v(" Edit ")]):_vm._e()])],1):_vm._e()])}),0),(_vm.tableData.length)?_c('div',{staticClass:"mt-5"},[_c('TablePagination',{attrs:{"pagination":_vm.pagination},on:{"pagechanged":_vm.onPageChange,"pagesizechanged":_vm.onPageSizeChanged}})],1):_c('div',[_c('p',{staticClass:"text-center font-weight-bold"},[_vm._v(" No data found ")])])],1)])],1)])]),(_vm.createRoleModal)?_c('CModal',{staticClass:"hide-footer custom-close-button",attrs:{"closeOnBackdrop":false,"size":"xl","title":"Add Role","color":"primary","show":_vm.createRoleModal},on:{"update:show":function($event){_vm.createRoleModal=$event}}},[_c('CreateRole',{on:{"onSavedRole":_vm.dismissCreateRoleModal}})],1):_vm._e(),(_vm.editRoleModal)?_c('CModal',{staticClass:"hide-footer custom-close-button",attrs:{"closeOnBackdrop":false,"size":"xl","title":"Edit Role","color":"primary","show":_vm.editRoleModal},on:{"update:show":function($event){_vm.editRoleModal=$event}}},[_c('EditRole',{attrs:{"roleId":_vm.roleId},on:{"onEditedRole":_vm.dismissEditRoleModal}})],1):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12"},[_c('h6',{staticClass:"text-uppercase font-weight-bold text-dark"},[_vm._v("Roles")])])}]

export { render, staticRenderFns }